var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "badge font-size-h6 font-w600 text-capitalize",
    class: _vm.badgeClass
  }, [_vm.status === 'pending' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-search fa-beat mr-1"
  }), _vm._v(" Reading invoice... ")]) : _vm.status === 'uploading' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-spinner fa-spin mr-1"
  }), _vm._v(" Uploading... ")]) : _vm.status === 'queued' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-pause-circle fa-beat mr-1"
  }), _vm._v(" Queued... ETA: < 1 minute")]) : _vm.status === 'processing' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-filter fa-beat mr-1"
  }), _vm._v(" Extracting text... ")]) : _vm.status === 'captured' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-check-circle mr-1"
  }), _vm._v(" Data Captured")]) : _vm.status === 'refreshing' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-spinner fa-spin mr-1"
  }), _vm._v(" Refreshing... ")]) : _vm.status === 'error' && _vm.error.type === 'pdf-load' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-exclamation-circle mr-1"
  }), _vm._v("Can't open PDF")]) : _vm.status === 'error' && _vm.error.type === 'pdf-pages' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-exclamation-circle mr-1"
  }), _vm._v("Too many pages")]) : _vm.status === 'error' && _vm.error.type === 'textract' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-exclamation-circle mr-1"
  }), _vm._v("OCR Error")]) : _vm.status === 'error' && _vm.error.type === 'captur' ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-exclamation-circle mr-1"
  }), _vm._v(_vm._s(_vm.error.message || 'Captur error'))]) : _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-exclamation-circle mr-1"
  }), _vm._v("Error")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }