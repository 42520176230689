<template>
  <div>
    <div v-if="validationResults?.results?.summary?.length > 0">
      <h3 class="mb-2">
        Validation

        <span v-if="validationResults.results.messages && validationResults.results.messages.length > 0" class="font-size-lg">
          <span class="badge badge-warning mr-2"
            >{{ validationResults.results.messages.filter(result => result.type === 'warning').length }} issues</span
          >
          <span class="badge badge-danger">{{ validationResults.results.messages.filter(result => result.type === 'danger').length }} warnings</span>
        </span>
      </h3>
      <p class="mb-2">
        This invoice has been validated against a set of rules. Checks are made against related invoices, accounts and contracts. Anything that may be
        an issue or problem will be highlighted below. For example, if the invoice dates overlap with another invoice on the same account.
      </p>
      <p class="mb-2">
        Validation results for the invoice as a whole are shown directly below. Any validation results for a specific fields will be shown beside the
        respective field. For example, if an invoice end reading does not follow on from a previous invoice's start read, this will show beside the
        <strong>End Read</strong> field.
      </p>
      <div v-for="(item, index) in validationResults.results.summary" :key="index">
        <div class="alert" :class="`alert-${item.type}`" role="alert">
          <p class="mb-0">{{ item.message }} <router-link v-if="item.link" class="font-w600" :to="item.link">Details</router-link></p>
        </div>
      </div>
    </div>

    <div v-if="validationResults.results.values.length > 0">
      <p><strong>Entity Name: </strong> {{ invoice.account ? invoice.account.entity?.legalName : 'N/A' }}</p>
      <h3 class="mb-2">Invoice Details</h3>
      <p>Any non-financial details related to the invoice are shown below, such as the invoice number, start/end dates and meter information.</p>
      <table class="table table-striped mb-4">
        <thead>
          <tr>
            <th>Field</th>
            <th>Invoice/Calculated Value</th>
            <th>Saved Value</th>
            <th>Validation</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="value in validationResultsMetadata" :key="value.key">
            <td class="font-w600">{{ value.name }}</td>
            <td>
              {{ value.friendlyValue }}<br />
              <span class="text-info">{{ value.friendlyCalculatedValue }}</span>
            </td>
            <td class="text-info font-w600">
              <FormItem
                :id="value.key"
                :type="value.formType"
                :options="[]"
                :value="value.formType === 'datePicker' ? value.calculatedValue : value.friendlyCalculatedValue"
                @input="input => onInputValue(input, value.key)"
              />
            </td>
            <td class="tfont-w600">
              <span v-for="(item, index) in value.validation" :key="index" :class="`text-${item.type}`">
                {{ item.message }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="mb-0">Rates</h3>
        <span>
          <button class="btn btn-alt-info mr-3" @click="onClickRevalidate">
            <span v-if="loading.revalidateInvoice[invoice._id]"><i class="fa fa-fw fa-spin fa-redo"></i> Refreshing Contract Rates...</span>
            <span v-if="!loading.revalidateInvoice[invoice._id]"><i class="fa fa-fw fa-redo"></i> Refresh Contract Rates</span>
          </button>
          <button class="btn btn-primary" @click="onClickAddRate">Add Rate</button>
        </span>
      </div>

      <p>All the rates related to this invoice. Changing the consumption or cost of a rate will automatically update the totals table below.</p>

      <strong>Note about contracts</strong>
      <p>
        Unit rates will be validated against their respective contract rate (if a contract exists for this invoice's account). If this is the first
        invoice uploaded for an account, you will need to map each unit rate to their respective contract rates. Contracts and contract rates can be
        managed from the Account Management area (Select an Asset -> Accounts tab -> Contracts).
      </p>
      <p>
        If you have made a change to a contract, use the blue <strong>Re-validate</strong> button above to check your changes against this invoice.
      </p>
      <table class="table mb-4">
        <tbody v-for="(rate, index) in rates" :id="`rate-${rate.id}`" :key="rate.id">
          <tr>
            <th class="bg-light" colspan="4">
              <div class="d-flex justify-content-between align-items-center">
                <span class="font-size-h5">{{ rate.rateName }}</span>
                <span>
                  <button
                    v-if="
                      ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) && rate.inputs.startRead === null && rate.inputs.startRead === null
                    "
                    class="btn btn-primary btn-sm mr-3"
                    @click="onClickAddReads(rate)"
                  >
                    <i class="fa fa-tachometer-alt mr-1"></i> Add Reads
                  </button>
                  <button
                    v-if="rate.inputs.startRead !== null || rate.inputs.startRead !== null"
                    class="btn btn-alt-danger btn-sm mr-3"
                    @click="onClickRemoveReads(rate)"
                  >
                    <i class="fa fa-tachometer-alt mr-1"></i> Remove Reads
                  </button>
                  <button
                    v-if="rate.inputs.startDate === null && rate.inputs.startDate === null"
                    class="btn btn-primary btn-sm mr-3"
                    @click="onClickAddDates(rate)"
                  >
                    <i class="fa fa-calendar-alt mr-1"></i> Add Dates
                  </button>
                  <button
                    v-if="rate.inputs.startDate !== null || rate.inputs.startDate !== null"
                    class="btn btn-alt-danger btn-sm mr-3"
                    @click="onClickRemoveDates(rate)"
                  >
                    <i class="fa fa-calendar-alt mr-1"></i> Remove Dates
                  </button>
                  <button
                    class="btn btn-alt-danger btn-sm"
                    :class="{ disabled: loading.removeInvoiceRate[invoice._id] === index }"
                    @click="onClickRemoveRate(rate, index)"
                  >
                    <span v-if="loading.removeInvoiceRate[invoice._id] === index"><i class="fa fa-spinner fa-spin mr-1"></i> Removing</span>
                    <span v-else><i class="fa fa-ban mr-1"></i> Remove</span>
                  </button>
                </span>
              </div>
            </th>
          </tr>
          <tr>
            <td><strong>Type</strong></td>
            <td>{{ getFriendlyRateType(rate.type) }}</td>
            <td>
              <FormItem
                :id="`consumption-${rate.id}`"
                type="select"
                :value="rate.inputs.type"
                :options="rateTypes"
                @input="input => onInputRate(input, rate.id, 'type')"
              />
            </td>
          </tr>
          <tr v-if="rate.inputs.startDate !== null || rate.inputs.endDate !== null">
            <td><strong>Start Date</strong></td>
            <td>
              {{ rate.friendlyStartDate }}<br />
              <span class="text-info">{{ rate.friendlyCalculatedStartDate }}</span>
            </td>
            <td>
              <FormItem
                :id="`startDate-${rate.id}`"
                type="datePicker"
                :value="rate.inputs.startDate"
                @input="input => onInputRate(input, rate.id, 'startDate')"
              />
            </td>
            <td class="font-w600"><span v-html="rateValidationHtml(rate, 'startDate')"></span></td>
          </tr>
          <tr v-if="rate.inputs.startDate !== null || rate.inputs.endDate !== null">
            <td>
              <strong>End Date</strong>
            </td>
            <td>
              {{ rate.friendlyEndDate }}<br />
              <span class="text-info">{{ rate.friendlyCalculatedEndDate }}</span>
            </td>
            <td>
              <FormItem
                :id="`endDate-${rate.id}`"
                type="datePicker"
                :value="rate.inputs.endDate"
                @input="input => onInputRate(input, rate.id, 'endDate')"
              />
            </td>
            <td class="font-w600"><span v-html="rateValidationHtml(rate, 'endDate')"></span></td>
          </tr>
          <tr v-if="rate.type !== 'tax'">
            <td><strong>Consumption</strong></td>
            <td>
              {{ rate.friendlyConsumption }}<br />
              <span class="text-info">{{ rate.friendlyCalculatedConsumption }}</span>
            </td>
            <td>
              <FormItem
                :id="`consumption-${rate.id}`"
                type="number"
                step="0.01"
                :value="rate.inputs.consumption"
                @input="input => onInputRate(input, rate.id, 'consumption')"
              />
            </td>
            <td class="font-w600"><span v-html="rateValidationHtml(rate, 'consumption')"></span></td>
          </tr>
          <tr v-if="rate.unitRate !== null">
            <td><strong>Unit Rate</strong></td>
            <td>
              {{ rate.friendlyUnitRate }}<br /><span class="text-info">{{ rate.friendlyCalculatedUnitRate }}</span>
            </td>
            <td>
              <FormItem
                :id="`unitRate-${rate.id}`"
                type="number"
                step="0.01"
                :value="rate.inputs.unitRate"
                @input="input => onInputRate(input, rate.id, 'unitRate')"
              />
            </td>
            <td>
              <span class="font-w600" v-html="rateValidationHtml(rate, 'unitRate')"></span>
              <div v-if="(rate.validation || []).some(v => v.code === 'NO_CONTRACT_MAPPING')" class="input-group">
                <FormItem
                  :id="`contractRateType-${rate.id}`"
                  label="Contract Rates"
                  type="select"
                  :options="validationResults.results.contract.rates.map(r => ({ label: r.type, value: r.type }))"
                  @input="input => onSelectContractRateType(input, rate)"
                />
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" @click="onClickSubmitContractRateType(rate)">Submit</button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td><strong>Cost</strong></td>
            <td>
              {{ rate.cost }}<br /><span class="text-info">{{ rate.calculatedCost }}</span>
            </td>
            <td>
              <FormItem
                :id="`cost-${rate.id}`"
                type="number"
                step="0.01"
                :value="rate.inputs.cost"
                @input="input => onInputRate(input, rate.id, 'cost')"
              />
            </td>
            <td><span class="font-w600" v-html="rateValidationHtml(rate, 'cost')"></span></td>
          </tr>
          <tr v-if="rate.inputs.startRead !== null || rate.inputs.endRead !== null">
            <td :style="{ 'padding-top': '33px' }">
              <strong>Start Read</strong>
            </td>
            <td :style="{ 'padding-top': '33px' }">
              {{ rate.friendlyStartRead }}
              <span v-if="rate.friendlyStartReadType">({{ rate.friendlyStartReadType }})</span><br />
              <span class="text-info">
                {{ rate.friendlyCalculatedStartRead }}
                <span v-if="rate.friendlyCalculatedStartReadType">({{ rate.friendlyCalculatedStartReadType }})</span>
              </span>
            </td>
            <td>
              <div class="row">
                <div :class="displayRegister(invoice.account?.registerIds) ? 'col-md-6' : 'col-md-8'">
                  <div class="form-group mb-0">
                    <label>Value</label>
                    <div class="input-group">
                      <FormItem
                        :id="`startRead-${rate.id}`"
                        type="number"
                        :min="0"
                        step="0.01"
                        placeholder="Value"
                        :value="rate.inputs.startRead"
                        @input="input => onInputRate(input, rate.id, 'startRead')"
                      />
                      <div class="input-group-append w-50">
                        <FormItem
                          :id="`startReadUnits-${rate.id}`"
                          v-model="form.startReadUnit"
                          label="Start Read Unit"
                          :style="{ width: '100%' }"
                          :options="unitOptions"
                          type="select"
                          :is-alt="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="displayRegister(invoice.account?.registerIds) ? 'col-md-3' : 'col-md-4'">
                  <label> Type </label>
                  <FormItem
                    :id="`startReadType-${rate.id}`"
                    type="text"
                    placeholder="Type"
                    :value="rate.inputs.startReadType"
                    @input="input => onInputRate(input, rate.id, 'startReadType')"
                  />
                </div>
                <div v-if="displayRegister(invoice.account?.registerIds)" class="col-md-3">
                  <label> Register </label>
                  <FormItem
                    :id="`startReadRegister-${rate.id}`"
                    :value="rate.inputs.startReadRegister"
                    type="select"
                    placeholder="Register"
                    :options="filteredRegisterIds(invoice.account.registerIds)"
                    @input="val => onInputRate(val, rate.id, 'startReadRegister')"
                  />
                </div>
              </div>
            </td>
            <td :style="{ 'padding-top': '33px' }">
              <span class="font-w600" v-html="rateValidationHtml(rate, 'startRead')"></span>
            </td>
          </tr>
          <tr v-if="rate.inputs.startRead !== null || rate.inputs.endRead !== null">
            <td :style="{ 'padding-top': '33px' }">
              <strong>End Read</strong>
            </td>
            <td :style="{ 'padding-top': '33px' }">
              {{ rate.friendlyEndRead }}
              <span v-if="rate.friendlyEndReadType">({{ rate.friendlyEndReadType }})</span><br />
              <span class="text-info">
                {{ rate.friendlyCalculatedEndRead }} <span v-if="rate.friendlyCalculatedEndReadType">({{ rate.friendlyCalculatedEndReadType }})</span>
              </span>
            </td>
            <td>
              <div class="row">
                <div :class="displayRegister(invoice.account?.registerIds) ? 'col-md-6' : 'col-md-8'">
                  <div class="form-group mb-0">
                    <label>Value</label>
                    <div class="input-group">
                      <FormItem
                        :id="`endRead-${rate.id}`"
                        type="number"
                        :min="0"
                        step="0.01"
                        placeholder="Value"
                        :value="rate.inputs.endRead"
                        @input="input => onInputRate(input, rate.id, 'endRead')"
                      />
                      <div class="input-group-append w-50">
                        <FormItem
                          :id="`endReadUnits-${rate.id}`"
                          v-model="form.endReadUnit"
                          :style="{ width: '100%' }"
                          label="End Read Unit"
                          :options="unitOptions"
                          type="select"
                          :is-alt="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="displayRegister(invoice.account?.registerIds) ? 'col-md-3' : 'col-md-4'">
                  <label> Type </label>
                  <FormItem
                    :id="`endReadType-${rate.id}`"
                    type="text"
                    placeholder="Type"
                    :value="rate.inputs.endReadType"
                    @input="input => onInputRate(input, rate.id, 'endReadType')"
                  />
                </div>
                <div v-if="displayRegister(invoice.account?.registerIds)" class="col-md-3">
                  <label> Register </label>
                  <FormItem
                    :id="`endReadRegister-${rate.id}`"
                    :value="rate.inputs.endReadRegister"
                    type="select"
                    placeholder="Register"
                    :options="filteredRegisterIds(invoice.account.registerIds)"
                    @input="val => onInputRate(val, rate._id, 'endReadRegister')"
                  />
                </div>
              </div>
            </td>
            <td :style="{ 'padding-top': '33px' }"><span class="font-w600" v-html="rateValidationHtml(rate, 'endRead')"></span></td>
          </tr>
        </tbody>
      </table>

      <h3 class="mb-2">Totals</h3>
      <p>
        The below table are all the totals related to this invoice.
        <strong
          >Editing a total will not automatically update any related totals (e.g. updating "Total Tax" will not update "Total Cost"). These values
          will be used for reporting, so please ensure they are all correct.</strong
        >
      </p>

      <div v-if="recalculations.length > 0" class="alert alert-warning">
        Some totals have been re-calculated as changes were made to one or more rates.
      </div>
      <table class="table table-striped mb-4">
        <thead>
          <tr>
            <th>Field</th>
            <th>Current Value</th>
            <th>Confirmed Value</th>
            <th>Validation</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="value in validationResultsTotals" :key="value.key">
            <td class="font-w600">{{ value.name }}</td>
            <td>
              <div v-if="value.friendlyValue">{{ value.friendlyValue }}</div>
              <div v-if="value.friendlyCalculatedValue" class="text-info">{{ value.friendlyCalculatedValue }}</div>
            </td>
            <td class="text-info font-w600">
              <FormItem
                v-if="value.value !== null"
                :id="value.key"
                :class="{ 'is-valid': recalculations.filter(message => message.key === value.key).length }"
                :type="value.formType"
                :options="[]"
                :value="form[value.key]"
                @input="input => onInputValue(input, value.key)"
              />
              <FormItem
                v-else-if="value.type === 'select'"
                :id="value.key"
                :class="{ 'is-valid': recalculations.filter(message => message.key === value.key).length }"
                :options="[]"
                :type="value.formType"
                :value="form[value.key]"
                @input="input => onInputValue(input, value.key)"
              />
              <FormItem
                v-else
                :id="value.key"
                :class="{ 'is-valid': recalculations.filter(message => message.key === value.key).length }"
                :options="[]"
                :type="value.formType"
                :value="form[value.key]"
                @input="input => onInputValue(input, value.key)"
              />

              <div v-for="(item, index) in recalculations.filter(message => message.key === value.key)" :key="index" class="valid-feedback">
                [Updated] {{ item.message }}
              </div>
            </td>
            <td class="font-w600">
              <ul class="list-unstyled">
                <li v-for="(item, index) in value.validation" :key="index" :class="`text-${item.type}`">
                  {{ item.message }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <button
        type="button"
        class="btn btn-primary btn-block"
        :class="{ disabled: loading.confirmInvoice[invoice._id] }"
        @click.prevent="onClickSubmit"
      >
        <span v-if="!loading.confirmInvoice[invoice._id]">Confirm Invoice</span>
        <span v-else><i class="fa fa-spinner fa-spin"></i> Confirming Invoice...</span>
      </button>
    </div>
    <ConfirmModal
      :open="!!modals.addRate"
      title="Add Rate"
      text="Please enter/select a rate name"
      lg-size
      @close="modals.addRate = false"
      @submit="onConfirmRateModal"
    >
      <AddRateForm v-model="modals.addRate" :register-ids="filteredRegisterIds(invoice.account.registerIds)" :unit-options="unitOptions" />
    </ConfirmModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import { calculateTotals, invoiceTotalKeys, rateTypes } from '../lib/invoice';

import AddRateForm from './AddRateForm';
import ConfirmModal from './ConfirmModal';
import FormItem from './FormItem';

const rateTotalsMap = {
  tax: {
    unitRate: ['totalTax', 'totalCost'],
    cost: ['totalTax', 'totalCost']
  },
  daily: {
    cost: ['totalDaily', 'netTotalCost', 'totalCost']
  },
  levy: {
    cost: ['totalLevy', 'netTotalCost', 'totalCost']
  },
  kva: {
    cost: ['totalKva', 'netTotalCost', 'totalCost']
  },
  waterRate: {
    consumption: ['totalWaterVolume'],
    cost: ['totalWaterCost', 'netTotalCost', 'totalCost']
  },
  wasteRate: {
    consumption: ['totalWasteVolume'],
    cost: ['totalWasteCost', 'netTotalCost', 'totalCost']
  },
  unitRate: {
    consumption: ['totalUnits'],
    cost: ['totalUnitCost', 'netTotalCost', 'totalCost']
  }
};

const friendlyRates = {
  unitRate: 'Unit Rate',
  consumption: 'Consumption',
  cost: 'Cost'
};

export default {
  name: 'InvoiceValidationForm',
  components: {
    AddRateForm,
    ConfirmModal,
    FormItem
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {},
      rates: [],
      selectedContractRateTypes: {},
      rateTypes: [...rateTypes],
      recalculations: [],
      modals: {
        addRate: false
      }
    };
  },
  computed: {
    ...mapGetters({
      invoiceKeys: 'invoiceValidation/invoiceKeys',
      invoices: 'invoiceValidation/invoices',
      loading: 'invoiceValidation/loading',
      units: 'util/units'
    }),
    unitOptions() {
      return this.units.filter(u => u.types.includes(this.invoice.account?.type)).map(u => ({ label: u.name, value: u.value }));
    },
    validationResults() {
      const invoiceIndex = this.invoices.findIndex(inv => inv._id === this.invoice._id);
      return this.invoices[invoiceIndex].validation;
    },
    validationResultsMetadata() {
      return this.validationResults.results.values.filter(value => !invoiceTotalKeys.includes(value.key));
    },
    validationResultsTotals() {
      return this.validationResults.results.values.filter(value => invoiceTotalKeys.includes(value.key));
    }
  },
  // watch: {
  //   validationResults() {
  //     this.populateRates();
  //   }
  // },
  async mounted() {
    this.populateRates();
  },
  methods: {
    ...mapActions({
      confirmInvoice: 'invoiceValidation/confirmInvoice',
      setContractRateType: 'invoiceValidation/setContractRateType',
      addInvoiceRate: 'invoiceValidation/addInvoiceRate',
      removeInvoiceRate: 'invoiceValidation/removeInvoiceRate',
      revalidateInvoice: 'invoiceValidation/revalidateInvoice'
    }),
    displayRegister(registerIds) {
      return this.filteredRegisterIds(registerIds).length > 1;
    },
    filteredRegisterIds(registerIds) {
      return [...new Set([...(registerIds || []), '1'])].map(id => ({ value: id, label: id }));
    },
    repopulateRates() {
      this.rates = this.rates.map(existingRate => {
        const matchingRate = this.validationResults.results.rates.find(rate => rate.id === existingRate.id);

        return matchingRate
          ? {
              ...matchingRate,
              inputs: existingRate.inputs
            }
          : existingRate;
      });
    },
    populateRates() {
      // Populate the input boxes
      // Prioritise invoice values, then calculated values

      if (this.validationResults.results && this.validationResults.results.values.length > 0) {
        this.validationResults.results.values.forEach(value => {
          this.form[value.key] = value.friendlyValue || value.friendlyCalculatedValue;
        });
      }

      if (this.validationResults.results && this.validationResults.results.rates.length > 0) {
        this.validationResults.results.rates.forEach(rate => {
          this.rates = [
            ...this.rates,
            {
              ...rate,
              inputs: {
                id: rate.id,
                type: rate.type,
                consumption: rate.consumption || rate.calculatedConsumption,
                unitRate: rate.unitRate || rate.calculatedUnitRate,
                cost: rate.cost || rate.calculatedCost,
                rateName: rate.friendlyRateName || rate.friendlyCalculatedRateName,
                startRead: rate.startRead || rate.calculatedStartRead,
                startReadType: rate.friendlyStartReadType || rate.friendlyCalculatedStartReadType,
                startReadRegister: rate.startReadRegister || '1',
                startReadUnit: rate.startReadUnit,
                endRead: rate.endRead || rate.calculatedEndRead,
                endReadType: rate.friendlyEndReadType || rate.friendlyCalculatedEndReadType,
                endReadRegister: rate.endReadRegister || '1',
                endReadUnit: rate.endReadUnit,
                startDate: rate.friendlyStartDate || rate.friendlyCalculatedStartDate,
                endDate: rate.friendlyEndDate || rate.friendlyCalculatedEndDate
              }
            }
          ];
        });
      }
    },
    getFriendlyRateType(type) {
      const rateType = this.rateTypes.find(rt => rt.value === type);

      if (rateType) return rateType.label;

      return 'N/A';
    },
    rateValidationHtml(rate, subKey) {
      const typeTemplates = {
        success: m =>
          m
            ? `<i class="fa fa-fw fa-check-circle text-success mr-1"></i><span class="text-success">${m}</span>`
            : '<i class="fa fa-check-circle text-success">',

        info: m =>
          m
            ? `<i class="fa fa-fw fa-info-circle text-info mr-1"></i><span class="text-info">${m}</span>`
            : '<i class="fa fa-info-circle text-info"></i>',

        warning: m =>
          m
            ? `<i class="fa fa-fw fa-exclamation-triangle text-warning mr-1"></i><span class="text-warning">${m}</span>`
            : '<i class="fa fa-exclamation-triangle text-warning"></i>',

        danger: m =>
          m
            ? `<i class="fa fa-fw fa-times-circle text-danger mr-1"></i><span class="text-danger">${m}</span>`
            : '<i class="fa fa-times-circle text-danger"></i>'
      };

      if (!rate.validation) return typeTemplates.success();

      const subKeyValidation = rate.validation.filter(v => v.subKey === subKey);

      if (subKeyValidation.length === 0) {
        return typeTemplates.success();
      }

      return subKeyValidation
        .filter(v => !!typeTemplates[v.type])
        .map(v => typeTemplates[v.type](v.message))
        .join('<br/>');
    },
    async onClickSubmit() {
      const updateResult = await this.confirmInvoice({
        id: this.invoice._id,
        data: {
          values: this.form,
          rates: this.rates.map(r => r.inputs),
          validation: this.validationResults.results.messages
        }
      });

      if (updateResult) {
        this.$toasted.success('Saved invoice successfully.');
        this.$emit('completed');
      } else {
        this.$toasted.error('Could not save invoice.');
      }
    },
    onInputValue(newValue, key) {
      this.form[key] = newValue;

      this.recalculations = this.recalculations.filter(calculation => calculation.key !== key);
    },
    onInputRate(newValue, rateId, ratePrefix) {
      const rate = this.rates.find(rate => rate.id === rateId);

      const hasValueChanged = parseFloat(newValue) !== parseFloat(rate.inputs[ratePrefix]);

      rate.inputs[ratePrefix] = newValue;

      const relatedTotals = rateTotalsMap[rate.type] && rateTotalsMap[rate.type][ratePrefix];

      if (relatedTotals && relatedTotals.length > 0 && hasValueChanged) {
        relatedTotals.forEach(totalKey => {
          const rateKey = `${totalKey}|${rate.rateName || rate.key}|${ratePrefix}`;

          this.recalculations = [
            ...this.recalculations.filter(message => message.rateKey !== rateKey),
            {
              key: totalKey,
              rateKey,
              message: `${friendlyRates[ratePrefix]} changed in rate "${rate.rateName || rate.key}"`
            }
          ];
        });

        this.recalculateTotals();
      }
    },
    async onClickRevalidate() {
      await this.revalidateInvoice({ id: this.invoice._id });
      await this.repopulateRates();
    },
    recalculateTotals() {
      const totals = calculateTotals(this.rates, this.form, 'inputs');
      Object.assign(this.form, totals);
    },
    onSelectContractRateType(input, rate) {
      if (input) {
        this.$set(this.selectedContractRateTypes, rate.calculatedRateName || rate.rateName, input);
      } else {
        delete this.selectedContractRateTypes[rate.calculatedRateName || rate.rateName];
      }
    },
    async onClickSubmitContractRateType() {
      await this.setContractRateType({
        invoiceId: this.invoice._id,
        accountId: this.invoice.accountId,
        contractId: this.validationResults.results.contract._id,
        data: this.selectedContractRateTypes
      });

      this.selectedContractRateTypes = {};

      await this.revalidateInvoice({ id: this.invoice._id });

      await this.repopulateRates();
    },
    onClickAddRate() {
      const newRate = {
        rateName: 'New Rate',
        type: 'unitRate',
        consumption: 0,
        unitRate: 0,
        cost: 0,
        startRead: null,
        startReadType: null,
        endRead: null,
        endReadType: null,
        startDate: this.invoice.values?.startDate || null,
        endDate: this.invoice.values?.endDate || null,
        endReadUnit: this.unitOptions[0].value,
        startReadUnit: this.unitOptions[0].value
      };

      this.modals.addRate = newRate;
    },
    async onClickRemoveRate(rate, index) {
      await this.removeInvoiceRate({
        id: this.invoice._id,
        rateId: rate.id,
        index
      });

      this.rates = this.rates.filter(r => r.id !== rate.id);

      this.revalidateInvoice({ id: this.invoice._id });
    },
    refreshValidationResults() {},
    onClickAddReads(rate) {
      rate.inputs.startRead = 0;
      rate.inputs.endRead = 0;
      rate.inputs.startReadType = 'E';
      rate.inputs.endReadType = 'E';
      rate.inputs.startReadRegister = '1';
      rate.inputs.endReadRegister = '1';
      rate.inputs.startReadUnit = this.unitOptions[0].value;
      rate.inputs.endReadUnit = this.unitOptions[0].value;
    },
    onClickAddDates(rate) {
      rate.inputs.startDate = this.invoice.values?.startDate !== null ? this.invoice.values?.startDate : undefined;
      rate.inputs.endDate = this.invoice.values?.endDate !== null ? this.invoice.values?.endDate : undefined;
    },
    onClickRemoveDates(rate) {
      rate.inputs.startDate = null;
      rate.inputs.endDate = null;
    },
    onClickRemoveReads(rate) {
      rate.inputs.startRead = null;
      rate.inputs.endRead = null;
      rate.inputs.startReadType = null;
      rate.inputs.endReadType = null;
    },
    async onConfirmRateModal() {
      await this.addInvoiceRate({
        id: this.invoice._id,
        data: {
          ...this.modals.addRate
        }
      });

      const invoiceValidation = await this.revalidateInvoice({ id: this.invoice._id });

      const newRates = invoiceValidation.rates.filter(r => !this.rates.some(existingRate => existingRate.id === r.id));

      this.rates.push(
        ...newRates.map(newRate => ({
          ...newRate,
          inputs: {
            id: newRate.id,
            type: newRate.type,
            consumption: newRate.consumption || newRate.calculatedConsumption,
            unitRate: newRate.unitRate || newRate.calculatedUnitRate,
            cost: newRate.cost || newRate.calculatedCost,
            rateName: newRate.friendlyRateName || newRate.friendlyCalculatedRateName,
            startRead: newRate.startRead || newRate.calculatedStartRead,
            startReadType: newRate.friendlyStartReadType || newRate.friendlyCalculatedStartReadType,
            startReadRegister: newRate.startReadRegister || '1',
            startReadUnit: newRate.startReadUnit,
            endRead: newRate.endRead || newRate.calculatedEndRead,
            endReadType: newRate.friendlyEndReadType || newRate.friendlyCalculatedEndReadType,
            endReadRegister: newRate.endReadRegister || '1',
            endReadUnit: newRate.endReadUnit,
            startDate: newRate.friendlyStartDate || newRate.friendlyCalculatedStartDate,
            endDate: newRate.friendlyEndDate || newRate.friendlyCalculatedEndDate
          }
        }))
      );

      this.$toasted.success('Rate added');

      this.modals.addRate = false;
    }
  }
};
</script>
