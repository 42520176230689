<template>
  <span>
    <small class="text-muted">{{ invoice.fileName }}</small>
    <br />
    {{ friendlyInvoiceName(invoice) }}</span
  >
</template>
<script>
import { friendlyInvoiceName } from '../lib/helpers';
export default {
  name: 'InvoiceName',
  props: {
    invoice: {
      type: Object,
      requred: true,
      default: () => ({})
    }
  },
  methods: {
    friendlyInvoiceName
  }
};
</script>
