var render = function () {
  var _vm$files$filter;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.showUpload ? _c('div', {
    staticClass: "mb-4 text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "upload-invoices-btn"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showUpload = !_vm.showUpload;
      }
    }
  }, [_vm._v("Upload Invoices")])]) : _vm._e(), _c('div', {
    staticClass: "push"
  }, [_vm.showUpload ? _c('div', {
    staticClass: "bg-lighter rounded-md p-4 mb-4",
    attrs: {
      "data-cy": "invoice-uploader"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-6 text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "hide-btn"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showUpload = !_vm.showUpload;
      }
    }
  }, [_vm._v("Hide")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "companies",
      "label": "Company",
      "type": "select",
      "options": _vm.companyList
    },
    model: {
      value: _vm.companyId,
      callback: function callback($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  })], 1), _c('div', {
    staticClass: "col-lg-3 col-md-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "assignedTo",
      "label": "Assigned To",
      "placeholder": "Not Assigned",
      "type": "select2",
      "config": {
        allowClear: true
      },
      "options": _vm.companyUsers
    },
    model: {
      value: _vm.assignedTo,
      callback: function callback($$v) {
        _vm.assignedTo = $$v;
      },
      expression: "assignedTo"
    }
  })], 1)]), _c('p', [_vm._v("Select any invoices you would like to upload.")]), _c('div', {
    staticClass: "form-group"
  }, [_c('input', {
    attrs: {
      "type": "file",
      "accept": ".pdf",
      "name": "invoices",
      "multiple": ""
    },
    on: {
      "change": _vm.onFileSelect
    }
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSubmitInvoices($event);
      }
    }
  }, [_vm._v("Upload")])]), _c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v("Options")]), _c('div', {
    staticClass: "custom-control custom-switch mb-1"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.options.useCaptur,
      expression: "options.useCaptur"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "options-use-captur",
      "type": "checkbox",
      "name": "options-use-captur"
    },
    domProps: {
      "checked": Array.isArray(_vm.options.useCaptur) ? _vm._i(_vm.options.useCaptur, null) > -1 : _vm.options.useCaptur
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.options.useCaptur,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.options, "useCaptur", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.options, "useCaptur", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.options, "useCaptur", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "options-use-captur"
    }
  }, [_vm._v("Use Captur")])]), _c('div', {
    staticClass: "small"
  }, [_vm._v(" Turn this off if you do not want Captur to automatically detect all invoice fields. All fields can still be manually amended regardless. Leave this on if you are unsure. ")])])]) : _vm._e(), _c('h3', {
    staticClass: "mb-1"
  }, [_vm._v("Processing Invoices")]), _c('p', [_vm._v("Invoices currently being processed by Captur.")]), _vm._l(_vm.files.filter(function (f) {
    return f.status !== 'captured' && f.status !== 'error';
  }), function (file) {
    return _c('div', {
      key: file.batchId,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [!file.showForm ? _c('div', {
      staticClass: "d-flex justify-content-between align-items-center",
      class: {
        'border-bottom': file.showForm
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('a', {
      staticClass: "mr-2",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickShowForm(file);
        }
      }
    }, [_c('strong', [_vm._v(_vm._s(file.name))])]), file.invoice.supplier && file.invoice.supplier.name ? _c('span', {
      staticClass: "badge badge-success text-capitalize mr-2"
    }, [_vm._v(_vm._s(file.invoice.supplier.name))]) : _vm._e(), file.invoice.type ? _c('span', {
      staticClass: "badge badge-info text-capitalize mr-2"
    }, [_vm._v(_vm._s(file.invoice.type) + " "), file.invoice.sisterId ? _c('span', [_vm._v(" (Multi)")]) : _vm._e()]) : _vm._e(), file.invoice.accountId ? _c('span', {
      staticClass: "badge badge-warning mr-2"
    }, [_vm._v("Account Match")]) : _vm._e(), file.invoice.templateVersion ? _c('span', {
      staticClass: "badge badge-secondary"
    }, [_vm._v(_vm._s(file.invoice.templateVersion))]) : _vm._e()]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.$auth.isAdmin ? _c('button', {
      staticClass: "btn btn-link font-w600 text-info",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickRecaptureInvoice(file.invoice);
        }
      }
    }, [_vm.loading.recaptureInvoice[file.invoice._id] ? _c('span', [_vm._v("Refreshing...")]) : _vm._e(), !_vm.loading.recaptureInvoice[file.invoice._id] ? _c('span', [_vm._v("Refresh")]) : _vm._e()]) : _vm._e(), _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      attrs: {
        "disabled": _vm.loading.confirmInvoice[file.invoice._id]
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickCancel(file.invoice);
          }($event);
        }
      }
    }, [_vm.loading.confirmInvoice[file.invoice._id] ? _c('span', [_vm._v("Cancelling...")]) : _vm._e(), !_vm.loading.confirmInvoice[file.invoice._id] ? _c('span', [_vm._v("Cancel")]) : _vm._e()]), _c('InvoiceUploadStatus', {
      staticClass: "ml-3",
      attrs: {
        "status": file.status,
        "error": file.invoice.error,
        "is-user": !!_vm.account
      }
    })], 1)]) : _c('div', {
      staticClass: "input-group"
    }, [_c('InvoiceResults', {
      attrs: {
        "invoice": _vm.invoice
      }
    })], 1)]), _c('div', {
      staticClass: "collapse",
      class: {
        show: file.showForm
      }
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    })])]);
  }), !_vm.erroredInvoices.length && ((_vm$files$filter = _vm.files.filter(function (f) {
    return f.status !== 'captured' && f.status !== 'error';
  })) === null || _vm$files$filter === void 0 ? void 0 : _vm$files$filter.length) === 0 ? _c('div', {
    staticClass: "alert alert-warning mb-4",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No invoices are currently being processed.")])]) : _vm._e(), _vm._l(_vm.erroredInvoices, function (invoice, index) {
    return _c('div', {
      key: index,
      staticClass: "alert alert-danger d-flex align-items-center justify-content-between"
    }, [_vm._v(" Error: Unable to process \"" + _vm._s(invoice.fileName) + "\" " + _vm._s(_vm.handleInvoiceErrorMsg(invoice.errorType)) + " "), _c('button', {
      staticClass: "btn btn-ghost-light p-0",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.removeErroredInvoice(invoice.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times"
    })])]);
  })], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-3"
  }, [_vm._v("Upload New Invoices")])]);
}]

export { render, staticRenderFns }