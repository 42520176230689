<template>
  <div>
    <Spinner v-if="loading">Loading preview...</Spinner>
    <div v-else class="d-flex justify-content-between align-items-center px-4">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click.prevent="currentPage -= 1">
            <i class="fa fa-angle-left"></i>
          </button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === pdfDocument.numPages }">
          <button class="page-link" @click.prevent="currentPage += 1">
            <i class="fa fa-angle-right"></i>
          </button>
        </li>
        <li class="page-item ml-3">
          <button class="page-link" @click="popup">
            <i class="fas fa-external-link-alt"></i>
          </button>
        </li>
      </ul>
      <div class="font-w700">Page {{ currentPage }} of {{ pdfDocument.numPages }}</div>
    </div>
    <div class="pdf-preview mb-4">
      <PdfViewer :src="pdfLoadingTask" :page="currentPage" />
    </div>
  </div>
</template>
<script>
import PdfViewer from 'vue-pdf';
import { mapActions } from 'vuex';

import Spinner from './Spinner';

export default {
  name: 'InvoiceValidationPreview',
  components: {
    PdfViewer,
    Spinner
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      loading: true,
      pdfDocument: null,
      pdfLoadingTask: null,
      browserUrl: null
    };
  },
  async mounted() {
    const previewUrls = await this.downloadInvoice({ id: this.invoice._id, name: 'preview' });

    this.pdfLoadingTask = PdfViewer.createLoadingTask(previewUrls.url);

    this.pdfDocument = await this.pdfLoadingTask.promise;
    this.browserUrl = previewUrls.browserUrl;

    this.loading = false;
  },
  beforeDestroy() {
    if (this.pdfLoadingTask) {
      this.pdfLoadingTask.destroy();
    }
  },
  methods: {
    ...mapActions({
      downloadInvoice: 'invoice/download'
    }),
    popup() {
      window.open(
        this.browserUrl,
        'popUpWindow',
        'height=1000,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
      );
      this.$emit('popup');
    }
  }
};
</script>
