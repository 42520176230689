<template>
  <div>
    <div v-if="!showUpload" class="mb-4 text-right">
      <button data-cy="upload-invoices-btn" class="btn btn-primary" @click.prevent="showUpload = !showUpload">Upload Invoices</button>
    </div>
    <div class="push">
      <div v-if="showUpload" data-cy="invoice-uploader" class="bg-lighter rounded-md p-4 mb-4">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-3">Upload New Invoices</h3>
          </div>
          <div class="col-6 text-right">
            <button data-cy="hide-btn" class="btn btn-primary" @click.prevent="showUpload = !showUpload">Hide</button>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-5">
            <FormGroup id="companies" v-model="companyId" label="Company" type="select" :options="companyList" />
          </div>
          <div class="col-lg-3 col-md-5">
            <FormGroup
              id="assignedTo"
              v-model="assignedTo"
              label="Assigned To"
              placeholder="Not Assigned"
              type="select2"
              :config="{ allowClear: true }"
              :options="companyUsers"
            />
          </div>
        </div>

        <p>Select any invoices you would like to upload.</p>
        <div class="form-group">
          <input type="file" accept=".pdf" name="invoices" multiple @change="onFileSelect" />
          <button type="button" class="btn btn-primary" @click.prevent="onSubmitInvoices">Upload</button>
        </div>

        <div class="form-group mb-0">
          <label>Options</label>
          <div class="custom-control custom-switch mb-1">
            <input id="options-use-captur" v-model="options.useCaptur" type="checkbox" class="custom-control-input" name="options-use-captur" />
            <label class="custom-control-label" for="options-use-captur">Use Captur</label>
          </div>
          <div class="small">
            Turn this off if you do not want Captur to automatically detect all invoice fields. All fields can still be manually amended regardless.
            Leave this on if you are unsure.
          </div>
        </div>
      </div>

      <h3 class="mb-1">Processing Invoices</h3>
      <p>Invoices currently being processed by Captur.</p>

      <div v-for="file in files.filter(f => f.status !== 'captured' && f.status !== 'error')" :key="file.batchId" class="block block-rounded mb-4">
        <div class="block-content block-content-full">
          <div v-if="!file.showForm" class="d-flex justify-content-between align-items-center" :class="{ 'border-bottom': file.showForm }">
            <div class="d-flex align-items-center">
              <a class="mr-2" href="#" @click.prevent="onClickShowForm(file)"
                ><strong>{{ file.name }}</strong>
              </a>
              <span v-if="file.invoice.supplier && file.invoice.supplier.name" class="badge badge-success text-capitalize mr-2">{{
                file.invoice.supplier.name
              }}</span>
              <span v-if="file.invoice.type" class="badge badge-info text-capitalize mr-2"
                >{{ file.invoice.type }} <span v-if="file.invoice.sisterId"> (Multi)</span></span
              >
              <span v-if="file.invoice.accountId" class="badge badge-warning mr-2">Account Match</span>
              <span v-if="file.invoice.templateVersion" class="badge badge-secondary">{{ file.invoice.templateVersion }}</span>
            </div>
            <div class="d-flex align-items-center">
              <button v-if="$auth.isAdmin" class="btn btn-link font-w600 text-info" @click.prevent="onClickRecaptureInvoice(file.invoice)">
                <span v-if="loading.recaptureInvoice[file.invoice._id]">Refreshing...</span>
                <span v-if="!loading.recaptureInvoice[file.invoice._id]">Refresh</span>
              </button>
              <button
                class="btn btn-link font-w600 text-danger"
                :disabled="loading.confirmInvoice[file.invoice._id]"
                @click.prevent="() => onClickCancel(file.invoice)"
              >
                <span v-if="loading.confirmInvoice[file.invoice._id]">Cancelling...</span>
                <span v-if="!loading.confirmInvoice[file.invoice._id]">Cancel</span>
              </button>
              <InvoiceUploadStatus class="ml-3" :status="file.status" :error="file.invoice.error" :is-user="!!account" />
            </div>
          </div>
          <div v-else class="input-group">
            <InvoiceResults :invoice="invoice" />
          </div>
        </div>
        <div class="collapse" :class="{ show: file.showForm }">
          <div class="block-content block-content-full"></div>
        </div>
      </div>

      <div
        v-if="!erroredInvoices.length && files.filter(f => f.status !== 'captured' && f.status !== 'error')?.length === 0"
        class="alert alert-warning mb-4"
        role="alert"
      >
        <p class="mb-0">No invoices are currently being processed.</p>
      </div>
      <div v-for="(invoice, index) in erroredInvoices" :key="index" class="alert alert-danger d-flex align-items-center justify-content-between">
        Error: Unable to process "{{ invoice.fileName }}" {{ handleInvoiceErrorMsg(invoice.errorType) }}
        <button type="button" class="btn btn-ghost-light p-0" @click="removeErroredInvoice(invoice.id)"><i class="fa fa-times"></i></button>
      </div>
    </div>
  </div>
</template>
<script>
import bluebird from 'bluebird';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import FormGroup from './FormGroup';
import InvoiceResults from './InvoiceResults';
import InvoiceUploadStatus from './InvoiceUploadStatus';

export default {
  name: 'InvoiceUpload',
  components: {
    FormGroup,
    InvoiceResults,
    InvoiceUploadStatus
  },
  props: {
    account: {
      type: Object,
      required: false,
      default: null
    },
    isUser: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      companyId: '',
      assignedTo: null,
      loadingIds: false,
      showUpload: false,
      options: {
        useCaptur: true,
        isManual: false
      },
      selectedFiles: [],
      filters: {
        sort: '',
        search: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      files: 'invoiceUpload/files',
      status: 'invoiceUpload/status',
      loading: 'invoiceUpload/loading',
      invoices: 'invoiceUpload/invoices',
      isPolling: 'invoiceUpload/isPolling',
      companies: 'company/companies',
      users: 'company/users',
      erroredInvoices: 'invoiceValidation/erroredInvoices'
    }),
    companyUsers() {
      const users = [...this.users.map(user => ({ label: user.name, value: user.userSub }))];

      users.sort((a, b) => a.label.localeCompare(b.label));

      return users;
    },
    companyList() {
      const companies = [...this.companies.map(company => ({ label: company.name, value: company._id }))];

      companies.sort((a, b) => a.label.localeCompare(b.label));

      return companies;
    }
  },
  async created() {
    this.loadingIds = true;

    // TODO: adminonly
    await this.listCompanies();
    await this.getInvoiceKeys();

    this.companyId = this.companies[0]._id;
    this.assignedTo = this.$auth.user.sub;

    this.options.isManual = this.isUser;

    await this.getProcessingInvoices();

    if (!this.isPolling) {
      this.poll({ timer: 10000 });
    }

    this.loadingIds = false;
  },
  methods: {
    ...mapActions({
      poll: 'invoiceUpload/poll',
      recaptureInvoice: 'invoiceUpload/refresh',
      upload: 'invoiceUpload/upload',
      getProcessingInvoices: 'invoiceUpload/getProcessingInvoices',
      confirmInvoice: 'invoiceUpload/confirmInvoice',
      cancelInvoice: 'invoiceUpload/cancelInvoice',
      listInvoices: 'invoice/list',
      listCompanies: 'company/list',
      getInvoiceKeys: 'invoiceUpload/getInvoiceKeys'
    }),
    ...mapMutations({
      addFile: 'invoiceUpload/ADD_FILE',
      updateFile: 'invoiceUpload/UPDATE_FILE',
      updateFileId: 'invoiceUpload/UPDATE_FILE_ID',
      addPollItem: 'invoiceUpload/ADD_POLL_ITEM',
      clearPolls: 'invoiceUpload/CLEAR_POLLS',
      removeErroredInvoice: 'invoiceValidation/REMOVE_ERRORED_INVOICE'
    }),
    handleInvoiceErrorMsg(type) {
      if (type === 'pdf-load') return 'as the file is corrupt';
      if (type === 'pdf-pages') return 'as there are too many pages. Please split the invoice into multiple files.';
      return '.';
    },
    onFileSelect(e) {
      const { name, files } = e.target;

      Array.from(Array(files.length).keys()).forEach(x => {
        const formData = new FormData();
        formData.append(name, files[x], files[x].name);

        this.selectedFiles.push({
          name: files[x].name,
          form: formData,
          progress: 0,
          type: null,
          status: 'uploading',
          id: Math.floor(Math.random() * 10000000000),
          invoice: {}
        });
      });
    },
    async onSubmitInvoices() {
      this.showUpload = false;

      const accountOptions = {};

      if (this.account) {
        if (this.account.supplierId) accountOptions.supplierId = this.account.supplierId;
        if (this.account.type) accountOptions.type = this.account.type;
        if (this.account.meterPointNumber) accountOptions.meterPointNumber = this.account.meterPointNumber;
        if (this.account.meterSerialNumber) accountOptions.meterSerialNumber = this.account.meterSerialNumber;

        accountOptions.accountId = this.account._id;
      }

      bluebird.map(
        this.selectedFiles,
        async file => {
          this.addFile(file);

          const invoice = await this.upload({
            name: file.id,
            data: file.form,
            options: { ...this.options, ...accountOptions, companyId: this.companyId, assignedTo: this.assignedTo }
          });

          this.updateFileId({ id: file.id, newId: invoice.batchId });
          this.updateFile({ id: invoice.batchId, status: 'queued' });
          this.addPollItem({ batchId: invoice.batchId, name: file.name, id: invoice.batchId, status: 'pending' });
        },
        {
          concurrency: 5
        }
      );

      this.selectedFiles = [];
    },
    onClickCancel(invoice) {
      this.cancelInvoice({ id: invoice._id });
    },
    onClickShowForm(file) {
      this.updateFile({ id: file.id, showForm: !file.showForm });
    },
    onClickRecaptureInvoice(invoice) {
      this.recaptureInvoice({ id: invoice._id });
    }
  }
};
</script>
<style lang="scss" scoped>
.results-progress {
  width: 180px;
}

.is-changed {
  color: #da4c4c !important;
  text-decoration: line-through;
  font-weight: 600;
}
</style>
