<template>
  <div class="badge font-size-h6 font-w600 text-capitalize" :class="badgeClass">
    <span v-if="status === 'pending'"> <i class="fa fa-fw fa-search fa-beat mr-1" /> Reading invoice... </span>
    <span v-else-if="status === 'uploading'"> <i class="fa fa-fw fa-spinner fa-spin mr-1" /> Uploading... </span>
    <span v-else-if="status === 'queued'"> <i class="fa fa-fw fa-pause-circle fa-beat mr-1" /> Queued... ETA: &lt; 1 minute</span>
    <span v-else-if="status === 'processing'"> <i class="fa fa-fw fa-filter fa-beat mr-1" /> Extracting text... </span>
    <span v-else-if="status === 'captured'"> <i class="fa fa-fw fa-check-circle mr-1" /> Data Captured</span>
    <span v-else-if="status === 'refreshing'"> <i class="fa fa-fw fa-spinner fa-spin mr-1" /> Refreshing... </span>
    <span v-else-if="status === 'error' && error.type === 'pdf-load'"> <i class="fa fa-fw fa-exclamation-circle mr-1" />Can't open PDF</span>
    <span v-else-if="status === 'error' && error.type === 'pdf-pages'"> <i class="fa fa-fw fa-exclamation-circle mr-1" />Too many pages</span>
    <span v-else-if="status === 'error' && error.type === 'textract'"> <i class="fa fa-fw fa-exclamation-circle mr-1" />OCR Error</span>
    <span v-else-if="status === 'error' && error.type === 'captur'">
      <i class="fa fa-fw fa-exclamation-circle mr-1" />{{ error.message || 'Captur error' }}</span
    >
    <span v-else> <i class="fa fa-fw fa-exclamation-circle mr-1" />Error</span>
  </div>
</template>
<script>
export default {
  name: 'InvoiceUploadStatus',
  props: {
    status: {
      type: String,
      required: true
    },
    error: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    badgeClass() {
      const statuses = {
        'badge-light': ['pending', 'uploading', 'queued', 'processing', 'refreshing'],
        'badge-success': ['captured']
      };

      const match = Object.keys(statuses).find(key => statuses[key].includes(this.status));

      return match || 'badge-warning';
    }
  }
};
</script>
