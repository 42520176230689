var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.invoice.fileName))]), _c('br'), _vm._v(" " + _vm._s(_vm.friendlyInvoiceName(_vm.invoice)))]);
}
var staticRenderFns = []

export { render, staticRenderFns }