<template>
  <div>
    <PageHeader title="Invoice Upload" sub-title="Upload invoices and preview the results" />
    <div class="content">
      <InvoiceUpload />

      <h3 class="mb-1">Unconfirmed Invoices</h3>
      <p>Invoices that have been processed, but require manual confirmation.</p>

      <InvoiceValidation />
    </div>
  </div>
</template>
<script>
import bluebird from 'bluebird';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import InvoiceUpload from '@/components/InvoiceUpload';
import PageHeader from '@/components/PageHeader';
import InvoiceValidation from '@/components/InvoiceValidation';

export default {
  name: 'InvoiceUploader',
  components: {
    InvoiceUpload,
    InvoiceValidation,
    PageHeader
  },
  data() {
    return {
      hasUploaded: false,
      loadingIds: false,
      suppliers: []
    };
  },
  computed: {
    ...mapGetters({
      files: 'invoiceUpload/files',
      loading: 'invoiceUpload/loading'
    })
  },
  methods: {
    ...mapActions({
      refresh: 'invoiceUpload/refresh'
    }),
    ...mapMutations({
      setFileStatus: 'invoiceUpload/SET_FILE_STATUS'
    }),
    onClickRefreshTemplates() {
      bluebird.map(
        this.files,
        async file => {
          this.setFileStatus({ name: file.name, status: 'refreshing' });
          await this.refresh({ id: file.invoice._id });
        },
        {
          concurrency: 3
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.results-progress {
  width: 180px;
}

.is-changed {
  color: #da4c4c !important;
  text-decoration: line-through;
  font-weight: 600;
}
</style>
